import axios from 'axios';

export const SpotifyService = {
    BASE_URL: 'https://api.spotify.com/v1',
    DEFAULT_LIMIT: 50,

    isPremium() {
        return JSON.parse(sessionStorage.spotify_user).product === 'premium';
    },

    async init() {
        const data = await this.getCurrentUsersProfile();
        sessionStorage.spotify_user = JSON.stringify(data);
    },

    async playTrack(spotifyTrackId) {
        try {
            const { data, error } = await axios.put(
                `${this.BASE_URL}/me/player/play`,
                {
                    uris: [`spotify:track:${spotifyTrackId}`]
                },
                {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.provider_token}`
                    }
                }
            );
            if (error) throw error;
            return data;
        } catch (error) {
            throw error;
        }
    },

    async addTrackToQueue(spotifyTrackId) {
        const { data, error } = await axios.post(
            `${this.BASE_URL}/me/player/queue?uri=spotify:track:${spotifyTrackId}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${sessionStorage.provider_token}`
                }
            }
        );
        if (error) throw error;
        return data;
    },

    async getCurrentUsersProfile() {
        const { data, error } = await axios.get(`${this.BASE_URL}/me`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.provider_token}`
            }
        });
        if (error) throw error;
        return data;
    },

    async getCurrentlyPlayingTrackData() {
        const { data, error } = await axios.get(`${this.BASE_URL}/me/player/currently-playing`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.provider_token}`
            }
        });
        if (error) throw error;
        return data;
    },

    async getUsersSavedTracks(next) {
        const url = next || `${this.BASE_URL}/me/tracks`;
        const { data, error } = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${sessionStorage.provider_token}`
            },
            params: {
                limit: this.DEFAULT_LIMIT
            }
        });
        if (error) throw error;
        return data;
    },

    async getTracks(trackIds) {
        if (trackIds.length > 50) {
            throw new Error('Spotify only allows 50 tracks to be fetched at a time');
        }
        const { data, error } = await axios.get(`${this.BASE_URL}/tracks`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.provider_token}`
            },
            params: {
                ids: trackIds?.join(','),
                limit: this.DEFAULT_LIMIT
            }
        });
        if (error) throw error;
        return data.tracks;
    },

    async getArtists(artistIds) {
        if (artistIds.length > 50) {
            throw new Error('Spotify only allows 50 artists to be fetched at a time');
        }
        const { data, error } = await axios.get(`${this.BASE_URL}/artists`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.provider_token}`
            },
            params: {
                ids: artistIds?.join(','),
                limit: this.DEFAULT_LIMIT
            }
        });
        if (error) throw error;
        return data.artists;
    },

    async getPlaylists(next) {
        const url = next || `${this.BASE_URL}/me/playlists`;
        const { data, error } = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${sessionStorage.provider_token}`
            },
            params: {
                limit: this.DEFAULT_LIMIT
            }
        });
        if (error) throw error;
        return data;
    },

    async getPlaylistTracks(playlist, next) {
        const url = next || playlist.tracks.href;
        const { data, error } = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${sessionStorage.provider_token}`
            },
            params: {
                limit: this.DEFAULT_LIMIT
            }
        });
        if (error) throw error;
        return data;
    },

    async addPlaylist(playlistName) {
        const spotifyUserId = JSON.parse(sessionStorage.session).user.user_metadata.provider_id;
        if (!spotifyUserId) return;
        const { data, error } = await axios.post(
            `${this.BASE_URL}/users/${spotifyUserId}/playlists`,
            {
                name: playlistName
            },
            {
                headers: {
                    Authorization: `Bearer ${sessionStorage.provider_token}`
                }
            }
        );
        if (error) throw error;
        return data;
    },

    async addPlaylistTracks(playlist, tracks) {
        if (tracks.length > 50) {
            throw new Error('Spotify only allows 50 tracks to be added at a time');
        }
        const { data, error } = await axios.post(
            `${this.BASE_URL}/playlists/${playlist.id}/tracks`,
            {
                uris: tracks.map((track) => track.uri)
            },
            {
                headers: {
                    Authorization: `Bearer ${sessionStorage.provider_token}`
                }
            }
        );
        if (error) throw error;
        return data;
    },

    async deletePlaylist(playlistId) {
        const { data, error } = await axios.delete(`${this.BASE_URL}/playlists/${playlistId}/followers`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.provider_token}`
            }
        });
        if (error) throw error;
        return data;
    },

    async refreshToken() {
        const { data, error } = await axios.post(
            'https://accounts.spotify.com/api/token',
            {
                grant_type: 'refresh_token',
                refresh_token: sessionStorage.provider_refresh_token,
                client_id: import.meta.env.VITE_SPOTIFY_CLIENT_ID
                // TODO: This only works if we supply a client_secret... THAT CAN'T BE RIGHT
                // Update: Supabase OAuth doesn't initiate the PKCE flow correctly, so it requires a client_secret for now
                // See https://github.com/supabase/auth/issues/1450#issuecomment-2287718083
            },
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        );

        if (error) throw error;
        if (data.access_token) {
            sessionStorage.provider_token = data.access_token;
        }
        if (data.refresh_token) {
            sessionStorage.provider_refresh_token = data.provider_refresh_token;
        }
        return data;
    }
};
